import React from 'react'
import diningRoom from '../images/9.jpg'
import diningRoom2 from '../images/11.jpg'

function Dining () {

    return (
        <div className="text-center align-center">
           <h1 className="text-lg font-bold underline">Local Dining in Osage Beach, MO</h1>
           <p>Lake House Hotel is near many dining options for you to choose from. For directions to our location and to book now, call <a className="font-bold hover:underline" href="tel:5736939336">573-693-9336</a>. Come taste the many flavors of the Lake of the Ozarks</p>
           <div className="grid grid-rows-2 grid-cols-1 py-3">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="flex justify-center pb-2">
                        <img className="w-5/6 h-5/6 lg:w-3/4 lg:h-64" src={diningRoom} alt=""/>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold underline">FREE Deluxe Continental Breakfast</h3>
                        <p>Before heading out to sample nearby Osage Beach restaurants, get your day started the right way with our very own continental breakfast bar featuring:</p>
                        <ul className="text-lg">
                            <li>Biscuits and Gravy</li>
                            <li>Hot and Cold Cereals</li>
                            <li>French Toast, Waffles, and Bagels</li>
                            <li>Fresh Fruit</li>
                            <li>Yogurt</li>
                            <li>Juices, Milk, Coffee & Tea</li>
                            <li>And So Much More...</li>
                        </ul>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 ">
                    <div className="flex justify-center">
                        <img className="w-5/6 h-5/6 " src={diningRoom2} alt=""/>
                    </div>
                    <div>
                        <h1 className="text-lg font-semibold underline" >Nearby Osage Beach Restaurants</h1>
                        <ul className="text-lg">
                            <li >Taco Bell (.05 mi)</li>
                            <li >Touch of Asia (.25 mi)</li>
                            <li >Wendy's (.5 mi)</li>
                            <li >Golden Corral (.5mi)</li>
                            <li >Steak n' Shake (.75 mi)</li>
                            <li >Bentley's (1 mi)</li>
                            <li >Outback Steakhouse (1 mi) </li>
                            <li >Applebee's (1 mi)</li>
                            <li >J B Hooks (2 mi)</li>
                            <li >Blue Heron (2 mi)</li>
                            <li >J Brunners (3 mi)</li>
                        </ul>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default Dining