import React from 'react'

import email from './../images/icons/envelope.svg'

function ContactUs () {
    return (
        <div className="text-center">
            <h1>Contact Information</h1>
            <div>
                <p>Address: 3518 Osage Beach Pkwy, Osage beach, Missouri 65065</p>
                <p>Contact: Mary Jo Shadel (General Manager)</p>
                <p>Front Desk and Reservations: 573-693-9336</p>
                <p>Fax: 573-693-9343</p>
                
            </div>

            {/* Form */}
            {/* <div className="mx-auto my-3">
                <form className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit>
                    <h1 className="text-center text-lg text-gray-900 font-bold">Reach Out!</h1>
                    <div className="mx-auto my-2">
                        <input className="px-2 py-2 border-solid border-2 rounded-md border-gray-600" type="email" name="fName" id="fName" placeholder="First Name"/>
                    </div>
                    
                    <div className="flex mx-auto my-2">
                        <input className="px-2 py-2 border-solid border-2 rounded-md border-gray-600" type="email" name="lName" id="lName" placeholder="Last Name"/>
                    </div>
                    
                    <div className="flex mx-auto my-2">
                        <input className="px-2 py-2 border-solid border-2 rounded-md border-gray-600" type="email" name="phone" id="phone" placeholder="Phone"/>
                    </div>
                    
                    <div className="flex mx-auto my-2">
                        <input className="px-2 py-2 border-solid border-2 rounded-md border-gray-600" type="email" name="email" id="email" placeholder="Email"/>
                    </div>
                    
                    <div className="flex mx-auto my-2">
                        <input className="px-2 py-2 border-solid border-2 rounded-md border-gray-600" type="email" name="email" id="email" placeholder="Message"/>
                    </div>

                    <button type="submit" className="px-3 py-2 border-solid border-2 border-gray-900 rounded sm bg-blue-500 text-white hover:text-gray-900 hover:bg-indigo-300 hover:font-bold">Send!</button>

                </form>
            </div> */}
        </div>
    )
}

export default ContactUs