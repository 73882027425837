import React, { Component } from 'react'

import Review from "./Review"

class Testimonials extends Component {
    constructor(props){
        super(props)

        this.state = {
            testimonials: [
                {
                    name: "Jeff R.",
                    message: "Very nice and extremely clean place. Perfect for family trip to the lake. Quiet Rooms, Family-friendly"
                },
                {
                    name: "Shannan S.",
                    message: "Clean Room. Great breakfast. Extra nice staff & the owners are super nice!!!!"
                },
                {
                    name: "Krystal N.",
                    message: "Kids (and adults) loved the two slides, one by the outdoor pool and one inside. Pools were clean and nice. Mini golf, shuffleboard, everything is included so no extra fees which isn't the case with some of the other places similiar to this in the area. Plus there is a playground the kids played on. We all enjoyed a warm breakfast in the morning. Such a great time for a reasonable rate. They have really used every square inch of their property to make this as fun as possible. The owners were kind and every staff we came in contact with smiled and spoke. They were sold out when we were there and it was NOT over crowded."
                },
                {
                    name: "Brenda R.",
                    message: "Very nice hotel. Clean place and friendly staff. Awesome Breakfast as well. Great outdoor area, pool, & fun waterslide plus other outdoor activities. Will definitely stay there again."
                },
                {
                    name: "Sarah R.",
                    message: "Immaculately clean. The water slide and pools were and enjoyable way to spend the evening. There are so many 'Extras' that a chain hotel doesn't offer. Miniature golf, shuffleboard, several childern's swings and park toys make it a very family-friendly hotel. We were greeted by the owner and she was friendly and welcoming. Breakfast was continental style and adequate for even picky eaters. You can definitley tell there is pride in ownership at Lake House Hotel. I'm telling you, everything is CLEAN and well maintained. You'll be glad you stayed! "
                },
                {
                    name: "Alison N.",
                    message: "My daughter and I stayed here one night on Oct 27, 2017. We really enjoyed the hospitality of the Lake House Hotel. Privately owned and operated by and incredibly nice couple who provide just the right amount of homey touches to make you feel welcomed. There are lots of things to do for the kids at the hotel including indoor/outdoor pools and waterslides, small minigolf course, shuffleboard, and playground. Everything is clean, well kept and carefully maintained. They also provide breakfast in the morning with a wide range of choices that are different from other places I've seen. The rooms are basic but do include a mini fridge and microwave, coffee maker and hairdryer. They are clean and fresh. There was plenty of room for the two of us. Highly recommend this hotel in Osage Beach!"
                },
                {
                    name: "Penny R.",
                    message: "Highly recommend you stay here if you visit the lake. We arrived early and were allowed to go ahead and check in. That was very much appreciated after a long drive. While checking in my daughter was babbling about Pokemon, her obsession. We dropped things off in our room and we went to leaveto look around and the gentleman that checked us in said to her 'I heard you talking about Pokemon' and gave her some kind of a Pokemon book. I really appreciate that! The rooms were very clean and the beds were so soft it waas like sleeping on a cloud. "
                },
                {
                    name: "BeeBee H.",
                    message: "We were in the area, buying a condo and this hotel was recommended to us by our realtor. The most important thing to our traveling teens is a pool. This indoor pool was awesome and they had so much fun on the waterslide. The outdoor pool and slide looked like it would be fun in warm weather. Breakfast is included and was not a basic continental. It had plenty of options and everyone was happy. There is a putt-putt course, basketball court and playground onsite, and lastly, the owners live in the hotel and are so attentive and friendly. Definitely would recommend to family and friends"
                },
                {
                    name: "Kevin L.",
                    message: "From the moment my wife called to book a room we were treated extremely well. Customer service, house keeping, and hospitality were all outstanding. Their indoor pool is well maintained and tere are things to do such as putt putt golf and a playground. This is by far the best hotel my family has been to."
                },
                {
                    name: "Charlene V.",
                    message: "Great place to stay, owners so sweet and accommodating"
                },
                {
                    name: "Mandy S.",
                    message: "Super nice place! Friendly staff... pool has a fun slide and hot tubs are awesome!"
                },
                {
                    name: "Nick H.",
                    message: "I have stayed at the Lake House Hotel 3 years running with my family and it never disappoints. It's the perfect place for families with children. There are indoor and outdoor swimming pools, both with water slides, hot tubs inside near pool, a good free breakfast, comfy rooms, and terrific service. Prices are extremely reasonable for the quality of service and clean, comfortable rooms. It's also convenient to just about anything you want to do at the lake. I highly recommend the Lake House Hotel!"
                }
            ]
        }
    }
    render(){
        
        const testimonialMap = this.state.testimonials.map((review, index) => <Review key={review.name + '1679305'}
                                                                                      name={review.name}
                                                                                      message={review.message}
                                                                                      index={index} />)     
        console.log(testimonialMap)
        return(
          <div>
              {testimonialMap}
          </div>
        )
    }
}

export default Testimonials
