import React, {Component} from 'react'

import './../styles/tailwind.output.css'
import logo from "../images/lakeHouseLogo.gif"

class Navbar extends Component{
    constructor(props){
        super(props)

        this.state = {
            dropdown: 1,
            isHidden: "hidden"
        }
    }
    
    render(){

        const toggleDropdown = () => {
            if(this.state.dropdown){
                this.setState({dropdown: 0})
            } else (
                this.setState({dropdown: 1})
            )

            if(this.state.dropdown){
                this.setState({isHidden: " "})
            } else {
                this.setState({isHidden: "hidden"})
            }
        }
        return(
            <div className="px-4 py-4 bg-gray-300 flex justify-between content-center">
                <div>
                    <a href="/"><img className="h-24 w-32" src={logo} alt=""/></a>
                </div>

                {/* Mobile NavBar */}
                <div className="lg:hidden">
                    <div className="">
                        <button className="my-auto border-solid border-2 border-gray-900 rounded-full p-2 shadow-xl" onClick={toggleDropdown}>
                            <svg className="h-8 w-8 mx-auto fill-current text-gray-900 hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                            </svg>
                        </button>
                    </div>
                    <div className={`block ${this.state.isHidden} mt-2 bg-gray-400 px-12 py-4 rounded-md`}>
                        
                            <a href="/Accomodations" className="text-gray-900 hover:text-indigo-500 hover:underline py-2">Accommodations</a>
                            <a href="/Amenities" className="text-gray-900 hover:text-indigo-500 hover:underline py-2">Amenities</a>
                            <a href="/Dining" className="text-gray-900 hover:text-indigo-500 hover:underline py-2">Dining</a>
                            <a href="/Testimonials" className="text-gray-900 hover:text-indigo-500 hover:underline py-2">Testimonials</a>
                            <a href="/ContactUs" className="text-gray-900 hover:text-indigo-500 hover:underline py-2">Contact Us</a>
                    </div>
                </div>

                {/* Desktop Navbar */}
                <div className="hidden lg:inline-flex mx-auto">

                    <ul className="flex justify-around">
                        <a href="/Accomodations" className="text-grey-900 hover:text-gray-100 hover:underline px-2">Accommodations</a>
                        <a href="/Amenities" className="text-grey-900 hover:text-gray-100 hover:underline px-2">Amenities</a>
                        <a href="/Dining" className="text-grey-900 hover:text-gray-100 hover:underline px-2">Dining</a>
                        <a href="/Testimonials" className="text-grey-900 hover:text-gray-100 hover:underline px-2">Testimonials</a>
                        <a href="/ContactUs" className="text-grey-900 hover:text-gray-100 hover:underline px-2">Contact Us</a>
                    </ul>

                    <div className="justify-end">
                        <a className="px-3 py-2 border-solid border-2 border-gray-900 rounded sm bg-blue-500 text-white hover:text-gray-900 hover:bg-indigo-300 hover:font-bold" href="https://live.ipms247.com/booking/book-rooms-lakehousehotel">Book Now!</a>
                    </div>

                </div>

    
            </div>
        )
    }

}

export default Navbar