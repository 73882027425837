import React from 'react'
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay'

import 'react-awesome-slider/dist/styles.css'

// Importing Room Images
    // Double Queen
import dQ1 from '../images/rooms/DQROOM.jpg'
import dQ2 from '../images/rooms/DQROOM2.jpg'
import dQ3 from '../images/rooms/DQROOM3.jpg'
import dQ4 from '../images/rooms/DQROOM4.jpg'
import dQ5 from '../images/rooms/DQROOM5.jpg'
import dQ6 from '../images/rooms/DQROOM6.jpg'
import dQ7 from '../images/rooms/DQROOM7.jpg'
import dQ8 from '../images/rooms/DQROOM8.jpg'

    // Handicap Room
import hC1 from '../images/rooms/HCROOM.JPG'
import hC2 from '../images/rooms/HCROOM2.JPG'
import hC3 from '../images/rooms/HCROOM3.jpg'
import hC4 from '../images/rooms/HCROOM4.jpg'
import hC5 from '../images/rooms/HCROOM5.jpg'

    // King Room
import K1 from '../images/rooms/KINGROOM.JPG'
import K3 from '../images/rooms/KINGROOM3.JPG'
import K4 from '../images/rooms/KINGROOM4.jpg'
import K5 from '../images/rooms/KINGROOM5.jpg'

    // Deluxe King
import dK1 from '../images/rooms/IMG_0092.jpg'
import dK2 from '../images/rooms/IMG_0093.jpg'
import dK3 from '../images/rooms/IMG_0094.jpg'
import dK4 from '../images/rooms/IMG_0095.jpg'

function Accomodations () {

    const AutoplaySlider = withAutoplay(AwesomeSlider)



    return (
        <div>
            <h1 className="text-center text-lg font-bold underline pb-2">Lake House Hotel Rooms</h1>
            <p className="px-5 text-center">Lake House Hotel provides conveinet, well-appointed hotel rooms in Osage Beach, Missouri with amenities that are sure the please both business and leisure trabelers alike. Out quiet and comfortable Lake of the Ozarks lodging options features cable television, free local calls and high-speed Internet access in all guest rooms as well as refrigerators and microwaves. If you are ready to book your hotel, call us at <a className="font-bold hover:underline" href="tel:5736939336">573-693-9336</a> today. We look forward to your stay</p>

            <div className="mt-12 grid grid-cols-2 ">
                <div className="pl-5"> 
                    <AutoplaySlider
                        play={false}
                        cancelOnInteraction={false}
                        interval={3000} >
                            <div data-src={dQ1} />
                            <div data-src={dQ2} />
                            <div data-src={dQ3} />
                            <div data-src={dQ4} />
                            <div data-src={dQ5} />
                            <div data-src={dQ6} />
                            <div data-src={dQ7} />
                            <div data-src={dQ8} />
                            

                    </AutoplaySlider>    
                </div>

                <div className="text-center my-auto">
                <h2 className=" text-lg font-bold underline">Two Queens Room</h2>
                    <ul>
                        <li>Internet Access - Wi-Fi</li>
                        <li>Flat Screen TV</li>
                        <li>Coffee Maker</li>
                        <li>Alarm Clock</li>
                        <li>Microwave</li>
                        <li>Refrigerator</li>
                        <li>Electronic Room Keys</li>
                        <li>Free Local Calls</li>
                    </ul>
                </div>
            </div>

            <div className="mt-12 grid grid-cols-2">
                <div className="pl-5"> 
                    <AutoplaySlider
                        play={false}
                        cancelOnInteraction={true}
                        interval={3000} >
                            <div data-src={hC1} />
                            <div data-src={hC2} />
                            <div data-src={hC3} />
                            <div data-src={hC4} />
                            <div data-src={hC5} />
                    </AutoplaySlider>    
                </div>

                <div className="text-center my-auto">
                    <h2 className="text-lg font-bold underline">Two Queen Handicap Accessible Room</h2>
                    <ul>
                        <li>Internet Access - Wi-fi</li>
                        <li>Flat Screen TV</li>
                        <li>Coffee Maker</li>
                        <li>Alarm Clock</li>
                        <li>Microwave</li>
                        <li>Refrigerator</li>
                        <li>Electronic Room Keys</li>
                        <li>Free Local Calls</li>
                        <li>Grab Bars by Toilet and in Shower/Bath</li>
                    </ul>
                </div>
            </div>

            <div className="mt-12 grid grid-cols-2">
                <div className="pl-5"> 
                    <AutoplaySlider
                        play={false}
                        cancelOnInteraction={true}
                        interval={3000} >
                            <div data-src={K1} />
                            <div data-src={K3} />
                            <div data-src={K4} />
                            <div data-src={K5} />
                            

                    </AutoplaySlider>    
                </div>
                <div className="text-center my-auto">
                    <h2 className="text-lg font-bold underline">King Room</h2>
                    <ul>
                        <li>Internet Access - Wi-Fi</li>
                        <li>Flat Screen TV</li>
                        <li>Coffee Maker</li>
                        <li>Alarm Clock</li>
                        <li>Microwave</li>
                        <li>Refriderator</li>
                        <li>Electronic Room Keys</li>
                        <li>Free Local Calls</li>
                        <li>Loveseat or Recliner</li>
                    </ul>
                </div>
            </div>

            <div className="mt-12 grid grid-cols-2 mb-12">
                <div className="pl-5">

                    <AutoplaySlider
                            play={false}
                            cancelOnInteraction={true}
                            interval={3000} >
                                <div data-src={dK1} />
                                <div data-src={dK2} />
                                <div data-src={dK3} />
                                <div data-src={dK4} />
                                

                        </AutoplaySlider>    

                </div>
                <div className="text-center my-auto">
                    <h2 className="text-lg font-bold underline">Two King Deluxe</h2>
                    <ul>
                        <li>Internet Access - Wi-Fi</li>
                        <li>3 Flat Screen TVs</li>
                        <li>Coffee Maker</li>
                        <li>Alarm Clock</li>
                        <li>Microwave</li>
                        <li>Refrigerator</li>
                        <li>Electronic Room Keys</li>
                        <li>Free Local Calls</li>
                        <li>Balcony</li>
                        <li>Queen Size Pull-Out Couch</li>
                        <li>Dining Table</li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Accomodations