import React from 'react';
import { Switch, Route} from "react-router-dom"
import './styles/tailwind.output.css'
import Navbar from './components/Navbar'

// Importing Pages for Links
import LandingPage from './pages/LandingPage'
import ContactUs from './pages/ContactUs'
import Footer from './components/Footer';
import Testimonials from './pages/Testimonials';
import Amenities from './pages/Amenities';
import Accomodations from './pages/Accomodations';
import Dining from './pages/Dining';


function App() {
  return (
    <div>
      <Navbar />
      
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/Amenities" component={Amenities} />
        <Route path="/Accomodations" component={Accomodations} /> 
        <Route path="/Dining" component={Dining} />
        <Route path="/ContactUs" component={ContactUs} />
        <Route path="/Testimonials" component={Testimonials} />

        <Route render={
          () => <h1>404 Not Found</h1>
        } />
      </Switch>

      <Footer />
    </div>
  );
}

export default App;
