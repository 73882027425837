import React from 'react'

import indoor from '../images/IndoorPoolwithwaterslide.jpg'
import outdoor from '../images/outdoorpoolwithslide.PNG'
import minigolf from '../images/golfandshuffle1.PNG'
import playground from '../images/playground2.PNG'
import shuffle from '../images/golfandshuffle2.PNG'
import gazebo from '../images/IMG_0064.jpg'
import basketball from '../images/basketball.JPG'
import cornhole from '../images/cornhole.JPG'

import wifi from '../images/freewifi.png'
import breakfast from '../images/breakfast.png'
import parking from '../images/parking2.png'
import reception from '../images/24hrs.png'
import smoking from '../images/nosmoking.png'
import pets from '../images/paw.png'
import calls from '../images/telephone.png'
import tv from '../images/monitor.png'


function Amenities(){
    return(
        <div className="text-center">
            <h1 className="text-lg font-bold underline">Amenities</h1>
            <p>Lake House Hotel provides great amenities to ensure that your stay is as enjoyable as possible. Call us for any questions at <a className="font-bold hover:underline" href="tel:5736939336">573-693-9336</a>, out friendly staff will be happy to answer all your questions and help you book your stay.</p>
            <div>
                <h1 className="text-lg font-bold underline">Hotel Amenities</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
                    <div>
                        <h1 className="py-1">Indoor Pool with Water Slide</h1>
                        <img className="mx-auto h-64" src={indoor} alt=""/>
                    </div>
                    <div>
                        <h1 className="py-1">Outdoor Pool with Water Slide</h1>
                        <img className="mx-auto w-64 h-64" src={outdoor} alt=""/>
                    </div>
                    <div>
                        <h1 className="py-1">Mini Golf</h1>
                        <img className="mx-auto w-64 h-64" src={minigolf} alt=""/>
                    </div>
                    <div>
                        <h1 className="py-1">Childerns Playground</h1>
                        <img className="mx-auto w-64 h-64" src={playground} alt=""/>
                    </div>
                    <div>
                        <h1 className="py-1">Shuffle Board</h1>
                        <img className="mx-auto w-64 h-64" src={shuffle} alt=""/>
                    </div>
                    <div>
                        <h1 className="py-1">Gazebo</h1>
                        <img className="mx-auto w-64 h-64" src={gazebo} alt=""/>
                    </div>
                    <div>
                        <h1 className="py-1">Basketball Court</h1>
                        <img className="mx-auto w-64 h-64" src={basketball} alt=""/>
                    </div>
                    <div>
                        <h1 className="py-1">Corn Hole</h1>
                        <img className="mx-auto w-64 h-64" src={cornhole} alt=""/>
                    </div>
                    <div>
                        <h1 className="py-1">Hot Tub</h1>
                        <img className="mx-auto w-64 h-64" src={indoor} alt=""/>
                    </div>
                </div>
            </div>
            <div>
                <h1 className="text-lg font-bold underline">Room Amenities</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 px-2 py-3">
                    <div className="">
                        <h1 className="py-1 text-xl font-semibold leading-lg">Free Wi-Fi</h1>
                        <img className="mx-auto" src={wifi} alt="" srcset=""/>
                    </div>
                    <div>
                        <h1 className="py-1 text-xl font-semibold leading-lg">Free Continental Breakfast</h1>
                        <img className="mx-auto" src={breakfast} alt="" srcset=""/>
                    </div>
                    <div>
                        <h1 className="py-1 text-xl font-semibold leading-lg">Free Parking</h1>
                        <img className="mx-auto" src={parking} alt="" srcset=""/>
                    </div>
                    <div>
                        <h1 className="py-1 text-xl font-semibold leading-lg">24 Hour Reception</h1>
                        <img className="mx-auto" src={reception} alt="" srcset=""/>
                    </div>
                    <div>
                        <h1 className="py-1 text-xl font-semibold leading-lg">No Smoking</h1>
                        <img className="mx-auto" src={smoking} alt="" srcset=""/>
                    </div>
                    <div>
                        <h1 className="py-1 text-xl font-semibold leading-lg">Pets Allowed (Pet Fee)</h1>
                        <img className="mx-auto" src={pets} alt="" srcset=""/>
                    </div>
                    <div>
                        <h1 className="py-1 text-xl font-semibold leading-lg">Free Local Calls</h1>
                        <img className="mx-auto" src={calls} alt="" srcset=""/>
                    </div>
                    <div>
                        <h1 className="py-1 text-xl font-semibold leading-lg">Flat Screen TV</h1>
                        <img className="mx-auto" src={tv} alt="" srcset=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Amenities 