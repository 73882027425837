import React, { Component } from 'react'

import droneShot from '../images/drone.png'

class LandingPage extends Component{
    constructor(props){
        super(props)

        this.state = {

        }
    }

    render() {
        
        return(
            <div className="bg-gray-100">
                <h1 className="text-2xl text-center font-bold text-gray-900 -mb-6">Welcome to the Lake House Hotel!</h1>
                <div className="mt-8 ">
                    <img className="max-w-md md:max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto shadow-xl" src={droneShot} alt=""/>
                </div>

                {/* Call to Action */}
                <div className="mx-2 my-4 text-center">
                    <a href="https://live.ipms247.com/booking/book-rooms-lakehousehotel" className="px-3 py-2 border-solid border-2 border-gray-900 rounded sm bg-blue-500 text-white hover:text-gray-900 hover:bg-indigo-300 hover:font-bold">
                        <span className="">Book Now!</span>
                    </a>
                    <a href=""></a>
                </div>

                {/* About */}
                <div className="text-center">
                    <h1 className="text-xl underline font-bold">Lake of the Ozarks Hotel</h1>
                    {/* Short About Section */}
                    <div className="px-2 py-3">
                        <p className="font-medium leading-relaxed">Lake House Hotel provides travelers with a refreshing respite from life on the road. Enjoy well-appointed, hotel accommodations paired with unparalleled hospitality. Take advantage of out generous amenities such as a complimentary Continental Breakfast, High-Speed internet access, an indoor swiming pool, and more at our top hotel near Lake of the Ozarks. Come stay with us and enjoy out outdoor pool and out new slide, the Lake House Gusher! For reservations or more information call us at <a className="text-orange-500 hover:underline hover:text-orange-400" href="tel:5736939336">573-693-9336</a>  </p>
                    </div>
                    {/* Awards */}
                    <h1 className="text-xl underline font-bold">Awards</h1>
                    <div className="px-2 py-3">
                        <p className="font-medium leading-relaxed">It's not hard to see how charming the Lake House Hotel in Osage Beach is. Come see for yourself why the hotel was awarded for Exceptional Curb Appeal in 2012. Trip Advisor Certificate of Excellence Awward Winner 2013, 2014, 2015 2016, 2017, 2018.</p>
                    </div>
                    {/* Discounts */}
                    <h1 className="text-xl underline font-bold">Discounts</h1>
                    <div className="px-2 py-3">
                        <p className="font-medium leading-relaxed">Since 2011, Lake House Hotel has offered discounts to senior citizens and out military service men and women. We also offer AAA and AARP discounts. Call us for more information at <a className="text-orange-500 hover:underline hover:text-orange-400" href="tel:5736939336">573-693-9336</a></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default LandingPage;