import React, { Component } from 'react'

class Review extends Component {
    constructor(props){
        super(props)

        this.state = {
            bgColor: ""
        }
    }

    componentDidMount(){
        if(this.props.index % 2) {
            this.setState({bgColor: "bg-gray-300"})
        } else {
            this.setState({bgColor: "bg-white"})
        }
    }

    render() {
            
        return(
            <div className={`text-center px-2 py-3`}>
                <div className={`${this.state.bgColor}`}>
                    <p>{this.props.message}</p>
                    <h4 className="font-bold">~{this.props.name}</h4>
                </div>
            </div>
        )
    }

}

export default Review